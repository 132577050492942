import { createAction } from '@reduxjs/toolkit';
import { ChannelMemberResponse, UserResponse } from 'stream-chat';
import { ChannelType } from '../../text-chat/types';

export const updateChatUser = createAction<{ type: ChannelType; user: UserResponse }>(
    'messages/updateChatUser'
);

export const updateChannelMembers = createAction<{
    type: ChannelType;
    members: ChannelMemberResponse[];
}>('messages/updateChannelMembers');
