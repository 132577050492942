import { PermissionType, User } from '../../api-client/types';
import { Participant } from '../../room-client/common';

export type RoomPermissions = {
    [key in PermissionType]: boolean;
};

export type IssueReport = {
    name: string;
    description: string;
    // Associated field that is required when issue is selected
    requiredField?: string;
};

export enum RoomStates {
    Loading,
    LoadingRoom,
    Connecting,
    Reconnecting,
    VideoPending,
    VideoLoading,
    SubscribeError,
    RoomNotFound,
    OpenInBrowser,
    Disconnected,
    Error,
    Queued,
}

export enum NotificationType {
    CopiedLink = 'Copied Link',
    CopiedInviteLink = 'Copied Invite Link',
    ParticipantJoin = 'Participant Join',
    ParticipantDisconnect = 'Participant Disconnect',
    HostEndBroadcast = 'Host End Broadcast',
    HostPauseBroadcast = 'Host Pause Broadcast',
    HostSourceMuted = 'Host Source Muted',
    UnsupportedSite = 'Unsupported Site',
    HostPoorConnection = 'Host Poor Connection',
    HostRoomCreated = 'Host Room Created',
    MediaPermissionsMissing = 'Media Permissions Missing',
    CameraPermissionMissing = 'Camera Permission Missing',
    MicPermissionMissing = 'Mic Permission Missing',
    LocationServicesEnabled = 'Location Services Enabled',
    LocationServicesDisabled = 'Location Services Disabled',
    InRoomFeedback = 'In-Room Feedback',
    PmfFeedback = 'Product Market Fit Survey',
    ParticipantWinner = 'Participant Winner',
    ParticipantLoser = 'Participant Loser',
    WebDownloadAppPrompt = 'Desktop App Download Prompt',
    iPhoneAppDownloadPrompt = 'iPhone App Download Prompt',
    iPhoneAppEmailConfirmation = 'iPhone App Email Confirmation',
    iPhoneAppQrCode = 'iPhone App QR Code',
    CameraWarning = 'Camera Warning',
    ShareStream = 'Share Stream',
    ShareStreamPending = 'Share Stream Pending',
    ShareStreamConfirmation = 'Share Stream Confirmation',
    StreamTakeover = 'Stream Takeover',
    MagicEmail = 'Magic Email',
    MicMuted = 'Mic Muted',
    StageInvite = 'Stage Invite',
    RoomSignupConfirmation = 'Room Signup Confirmation',
    RoomArchived = 'Room Archived',
    RoomDeleted = 'Room Deleted',
    MemberBanned = 'Member Banned',
    ChatWindowBlocked = 'Chat Window Blocked',
    TvAuthConnected = 'TV Auth Connected',
    TvAuthVerified = 'TV Auth Verified',
    TvAuthAccessDenied = 'TV Auth Access Denied',
    TvAuthParseFailed = 'TV Auth Parse Failed',
    TvAuthStreamAccessRevoked = 'TV Auth Stream Access Revoked',
    TvAuthPortalPending = 'TV Auth Portal Pending',
    TvAuthPlatformError = 'TV Auth Platform Error',
    FlairTipConfirmation = 'Flair Tip Confirmation',
}

export enum Vote {
    Down,
    Up,
}

export type PopupNotification = {
    type: NotificationType;
    payload?: any;
    duration?: number;
};

type LoggerFunction = (...args: any[]) => void;

export type Logger = {
    log: LoggerFunction;
    error: LoggerFunction;
    warn: LoggerFunction;
    info: LoggerFunction;
    verbose: LoggerFunction;
    debug: LoggerFunction;
    dev: LoggerFunction;
};

export type MediaInputDevice = {
    label: string;
    deviceId: string;
    groupId: string;
    permission: boolean;
    enabled: boolean;
};

export enum StreamStatus {
    Ok,
    NetworkIssue,
    CpuIssue,
}

export enum UserFlag {
    PmfSurveyResponded = 'pmfSurveyResponded',
    PmfSurveyDismissed = 'pmfSurveyDismissed',
    DownloadPromptAccepted = 'downloadPromptAccepted',
    DownloadPromptDismissed = 'downloadPromptDismissed',
    IphoneBetaPromptAccepted = 'iphoneBetaPromptAccepted',
    IphoneBetaPromptDismissed = 'iphoneBetaPromptDismissed',
    IphoneAppInstalled = 'iphoneAppInstalled',
    StreamingServiceSurveyDismissed = 'streamingServiceSurveyDismissed',
    StreamingServiceSurveySubmitted = 'streamingServiceSurveySubmitted',
    TipGiphyDismissed = 'tipGiphyDismissed',
    TipHelpDismissed = 'tipHelpDismissed',
    TipVideoChatDrag = 'tipVideoChatDrag',
    TipRoomAudioBalance = 'tipRoomAudioBalance',
    TipMobileWebAudioBalance = 'tipMobileWebAudioBalance',
    TipRoomFlair = 'tipRoomFlair',
}

export type UserFlags = { [key in UserFlag]: any };

export interface RoomUser extends User {
    flair?: string;
}

export interface RoomParticipant extends Participant {
    flair?: string;
}
